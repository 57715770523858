import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Navigation from "../components/navigation"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import styled from "styled-components"

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  grid-gap: 2rem;
  margin: 0;
  margin-bottom: 15px;
  & .picture {
    margin: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  & .picture:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  & .picture:hover:after {
    opacity: 1;
    cursor: pointer;
  }
`

const Photos = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const images = data.allContentfulImages.edges
  const social = data.site.siteMetadata.social

  return (
    <Layout large={true} location={location} title={siteTitle} social={social}>
      <Seo title="Photos" />
      <Navigation />
      <Gallery>
        {images.map(({ node }) => {
          const image = getImage(node.image.gatsbyImageData)
          return (
            <Link to={`/photos/${node.id}`}>
              <GatsbyImage className="picture" image={image} alt={node.title} />
            </Link>
          )
        })}
      </Gallery>
      <hr />
      <Bio />
    </Layout>
  )
}

export default Photos

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          title
          url
        }
      }
    }
    allContentfulImages(sort: { fields: [dateTaken], order: DESC }) {
      edges {
        node {
          id
          title
          dateTaken
          imageTags
          lens {
            name
            model
            id
          }
          location {
            lon
            lat
          }
          description
          image {
            id
            gatsbyImageData(
              width: 500
              height: 500
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  }
`
